var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "terms-and-conditions" } }, [
    _vm.pageText !== null
      ? _c("div", [
          _c("div", { staticClass: "terms-conditions-head" }, [
            _c("h1", { staticClass: "text_1" }, [
              _vm._v(_vm._s(_vm.pageText.text_1)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "terms-condition-container" }, [
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_2)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_3)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q1)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_4)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_5)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q2)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_6)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q3)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_7)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q4)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_8)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q5)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_9)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_10)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q26)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_67)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q6)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_11)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_12)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q7)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_13)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q8)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_14)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q9)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_15)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q10)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_16)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_17)))]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_18))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_19))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_20)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_21)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_22)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q11)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_23)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_24)))]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_25))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_26))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_66))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q12)))]),
            _vm._v(" "),
            _c("ul", { staticClass: "feature_container" }, [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_28))),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_29)))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_30)))]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_31))),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_32)))]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_33))),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_34)))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_35)))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_36)))]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q13)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_37)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_38)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_39)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_40)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_41)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_42)))]),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q14)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q15)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_43)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_44)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q16)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_45)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q17)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_46)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q18)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_47)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_48)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_49)))]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_25))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_26))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_66))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q19)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_52)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q20)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_53)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q21)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_54)))]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_55))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_56))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_57))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_58))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_59))),
                ]),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", [
                  _vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_60))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_61)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q22)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_62)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q23)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_63)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q24)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_64)))]),
            _vm._v(" "),
            _c("h3", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.q25)))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.replaceText(_vm.pageText.text_65)))]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }