<template>
  <img
    v-if="imageData"
    :src="imageData"
    @click="onClick()"
    class="carousel-item"
  />
</template>

<script>
import { getAsset } from "../../aframe/utils/assets.utils";

const canvas = document.createElement("canvas");
const context = canvas.getContext("2d");

export default {
  props: {
    src: { type: String },
  },
  data: () => ({
    imageData: "",
  }),
  async mounted() {
    await getAsset(this.src);
    this.imageData = this.src;

    // const image = await getAsset(this.src);
    // canvas.width = image.width;
    // canvas.height = image.height;
    // context.drawImage(image, 0, 0, canvas.width, canvas.height);
    //
    // this.imageData = canvas.toDataURL("jpg");
  },
  beforeDestroy() {
    this.$refs.canvas = null;
  },
  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
};
</script>
