var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "generic-menu" },
    [
      _c("Background", {
        attrs: {
          src: "https://360fabriek-storage-drdhfqcsgcctcubg.z03.azurefd.net/xr-academy/backgrounds/biolab.jpg",
        },
      }),
      _vm._v(" "),
      !_vm.asset
        ? _c(
            "div",
            { staticClass: "content" },
            [_c("Carousel", { attrs: { items: _vm.carouselItems } })],
            1
          )
        : _c(
            "div",
            { staticClass: "content" },
            [_c("ModelMenu", { attrs: { items: _vm.carouselItems } })],
            1
          ),
      _vm._v(" "),
      _c("ChatGpt", {
        ref: "chatgptComponentChild",
        attrs: { category: _vm.category },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }