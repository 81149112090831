import { getAsset } from "../utils/assets.utils";
import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("load-asset", {
  schema: {
    animate: { type: "boolean", default: false },
    showLoader: { type: "boolean" },
    src: { type: "string" },
    waitForScene: { type: "boolean", default: true },
  },

  events: {
    materialtextureloaded: function () {
      if (!this.data.animate) {
        return;
      }

      this.el.setAttribute("animation__asset-loaded", {
        dur: 500,
        property: "components.material.material.color",
        to: "#fff",
        type: "color",
      });
    },
  },

  init: function () {
    this.assetLoaded = false;
    this.loadAsset = this.loadAsset.bind(this);

    this.scene = this.el.closest(".scene");
    this.scene.addEventListener("sceneEnter", this.loadAsset);
  },

  update: async function () {
    this.el.setAttribute("material", {
      color: "#000",
      shader: "flat",
    });

    this.loadIfSceneVisible();
  },

  remove: function () {
    this.scene.removeEventListener("sceneEnter", this.loadAsset);

    this.el.setAttribute("material", "src", null);
  },

  loadAsset: async function () {
    if (this.data.showLoader) {
      this.el.sceneEl.systems.loader.addIdForLoader(this.el);
    }

    this.el.setAttribute(
      "material",
      "color",
      this.data.animate ? "#000" : "#FFF"
    );

    const asset = await getAsset(this.data.src);
    this.el.setAttribute("material", "src", `#${asset.getAttribute("id")}`);
    this.assetLoaded = true;
    this.el.emit("assetLoaded");

    if (this.data.showLoader) {
      this.el.sceneEl.systems.loader.removeIdForLoader(this.el);
    }
  },

  loadIfSceneVisible: function () {
    if (this.scene.getAttribute("id") === `scene-${getState().scene}`) {
      this.loadAsset();
    }
  },
});
