<template>
  <div id="help-center">
    <div class="help-center-container">
      <div class="help_details">
        <h1>Help Center</h1>
        <span>
          XR Academy is a subscription-based edutainment platform. We're here to
          revolutionise the global learning landscape. If you have any questions
          or need assistance, feel free to get in touch with our customer care
          team.
        </span>
        <div class="contact_container">
          <div class="contact_list">
            <img src="images/credit-card-ui/email.png" alt="email" />
            <p>help@xr-academy.com</p>
          </div>
          <div class="contact_list">
            <img src="images/credit-card-ui/phone-call.png" alt="phone" />
            <p>0805 98 54 98 (France)</p>
          </div>
          <div class="contact_list">
            <img src="images/credit-card-ui/phone-call.png" alt="phone" />
            <p>900 751 051 (Spain)</p>
          </div>
          <div class="contact_list">
            <img src="images/credit-card-ui/phone-call.png" alt="phone" />
            <p>800 785 693 (Italy)</p>
          </div>
        </div>
        <div class="Unsub_btn" v-if="msisdnPresent" @click="unsubscribe">
          <span>Unsubscribe</span>
        </div>
        <p style="color: red; font-size: 12px" v-if="showErrorMessage">
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { webstore } from "../helpers/auth-helpers";
import { HELP_CENTER_UTM_CAMPAIGN } from "../helpers/page-constants";
// import { sendOnClick } from "../utils/dataCollection.utils";
import { redirectUnsubscribeForCreditCard } from "../utils/redirect.utils";
import { isCreditCardProduct } from "../utils/isCreditCardProduct";
// import { getLoginURL, redirectToLogin } from "../utils/redirect.utils";

export default {
  data: () => ({
    msisdnPresent: !!webstore.getProperty("msisdn"),
    errorMessage: "",
    showErrorMessage: false,
  }),
  async created() {
    waitForLoaderToHide();
  },
  methods: {
    unsubscribe: function () {
      // this.$emit("close");
      if (isCreditCardProduct) {
        redirectUnsubscribeForCreditCard(HELP_CENTER_UTM_CAMPAIGN);
        //const msisdn = webstore.getProperty("msisdn");
        // if (msisdn)
        //   // eslint-disable-next-line new-cap
        //   UnsubscribeForCreditCard(msisdn)
        //     .then((resp) => {
        //       if (resp === true) {
        //         this.hideError();
        //         sendOnClick({
        //           contentName: "optout",
        //           redirectUrl: getLoginURL().href,
        //         });
        //         redirectToLogin();
        //       } else this.showError("Something went wrong");
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       this.showError("Server Error");
        //     });
      }
      //else this.showError("This method is not allowed");
    },
    showError(msg) {
      this.showErrorMessage = true;
      this.errorMessage = msg;
    },
    hideError() {
      this.showErrorMessage = false;
      this.errorMessage = "";
    },
  },
};
</script>

<style lang="scss">
.help-center-container {
  color: #fff;
  margin-top: 11%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .help_details {
    margin: 0;
    padding: 2rem;
  }
  .Unsub_btn {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgba(45, 239, 224, 1) 0%,
      rgba(45, 155, 239, 1) 57%,
      rgba(53, 237, 251, 1) 100%
    );
    width: 80%;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    padding: 18px 0px;
    //margin-top: 10%;
  }
  .contact_container {
    margin-top: 3%;

    .contact_list {
      display: flex;
      align-items: center;
      margin-left: 5%;
      margin-bottom: 2%;

      img {
        width: 25px;
      }

      p {
        margin: 0 0 0% 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .help_details {
      .contact_container {
        margin-top: 5%;

        .contact_list {
          margin-bottom: 6%;
        }
      }
    }
  }
}
</style>
