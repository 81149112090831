<template>
  <div class="pwa-prompt dialog-box dialog-box-vue">
    <div @click="closeDialogBox" class="close-button">
      <img src="/images/ui/exit-button.png" />
    </div>
    <div class="pwa-heading">
      <h3>Need help? Our support team is ready to assist you.</h3>
      <p><b>Support Line:</b> <a href="tel:1800819748">1800819748</a></p>
      <p>
        <b>Email:</b>
        <a href="mailto:mycs@content-clubs.com">mycs@content-clubs.com</a>
      </p>
      <p>
        <b>Subscription:</b>
        <a href="https://checksubscription.com/?c=my"
          >https://checksubscription.com/?c=my</a
        >
      </p>
      <div class="pwa-buttons">
        <a
          href="https://api.whatsapp.com/send/?phone=601111673277&text&type=phone_number&app_absent=0"
          class="conatct-us-btn"
          target="_blank"
        >
          <button>
            <svg
              viewBox="0 0 20 20"
              class="message-us-button_logo"
              width="20px"
              height="20px"
            >
              <path
                fill-rule="evenodd"
                d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,
                20 C8.21764336,20 6.50261894,19.5321866 4.99604377,18.6597493 L1.09644722,19.9573724 C0.445123153,
                20.1741059 -0.17423773,19.5545345 0.0427170752,18.9032841 L1.34118515,15.0055699 C0.468152857,
                13.4986162 4.48352336e-12,11.7830015 4.48352336e-12,10 C4.48352336e-12,4.4771525 4.4771525,0 10,
                0 Z M10,1.66666667 C5.39762709,1.66666667 1.66666667,5.39762709 1.66666667,10 C1.66666667,
                11.6017128 2.11850111,13.1345631 2.95730951,14.4569627 C3.09146627,14.6684637 3.1233805,
                14.929089 3.04422002,15.1667113 L2.15025713,17.8501903 L4.83522181,16.956746 C5.07271735,
                16.8777174 5.33315744,16.9096255 5.54455056,17.0436501 C6.86661689,17.8818489 8.39890565,18.3333333 10,
                18.3333333 C14.6023729,18.3333333 18.3333333,14.6023729 18.3333333,10 C18.3333333,5.39762709 14.6023729,
                1.66666667 10,1.66666667 Z M6.55072391,5.00454308 C6.71472826,5.00454308 6.87873262,
                5.00454308 7.02048512,
                5.01164697 C7.1713032,5.02141482 7.37404224,4.94859994 7.57430887,5.46540794 C7.78034448,
                5.99642371 8.27235754,7.30442746 8.33334408,7.43584942 C8.39515477,7.56727138 8.43636189,
                7.72355696 8.35559593,7.90026623 C8.27318168,8.07786348 8.23032628,8.18886176 8.10835319,
                8.34070741 C7.98555596,8.49699298 7.85039659,8.68791003 7.73831322,8.80512421 C7.61551599,
                8.93832215 7.48694976,9.08039995 7.63199884,9.34679582 C7.77375134,9.61052774 8.26906097,
                10.4807543 9.00007535,11.1822634 C9.94042191,12.0871214 10.7324228,12.368613 10.9796656,
                12.500035 C11.2244359,12.6323449 11.369485,12.6074813 11.5112375,12.434324 C11.6554624,
                12.2576147 12.1252236,11.66 12.2900521,11.3936041 C12.4540565,11.1298722 12.618885,
                11.1716076 12.8438758,
                11.2621822 C13.0688667,11.3492048 14.279532,11.9903309 14.5243024,12.1217528 C14.7723692,
                12.2567268 14.9363736,12.3233257 14.9973601,12.434324 C15.0591708,12.5453223 15.0591708,
                13.0763381 14.8556076,13.6934885 C14.649572,14.3141909 13.6680183,14.8798381 13.1949605,
                14.955317 C12.769703,15.0219159 12.2323621,15.0494435 11.6431003,14.8487586 C11.1671235,
                14.6897973 10.6994329,14.5033465 10.2420581,14.2902153 C7.77375134,13.1411611 6.16090452,
                10.4638825 6.03893144,10.2907252 C5.91531006,10.113128 5.03347762,8.8512995 5.03347762,
                7.5468477 C5.03347762,6.24328389 5.66806732,5.60126983 5.89305822,5.33753792 C6.11887326,
                5.07025406 6.38589542,5.00454308 6.55072391,5.00454308 Z"
                fill="#25D366"
              ></path>
            </svg>
            Whatsapp
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // Jab component load ho jaye, tab body par class add karein
    const body = document.body; // Direct document.body ka use karein
    if (body) {
      body.classList.add("modal-open");
    }
  },
  beforeDestroy() {
    // Jab component destroy ho raha ho, tab body se class remove karein
    const body = document.body; // Direct document.body ka use karein
    if (body) {
      body.classList.remove("modal-open");
    }
  },
  methods: {
    closeDialogBox() {
      // Popup band hone par body se class remove karein
      const body = document.body; // Direct document.body ka use karein
      if (body) {
        body.classList.remove("modal-open");
      }
      this.$el.style.display = "none"; // Popup ko hide kar de
    },
  },
};
</script>

<style scoped lang="scss">
.pwa-prompt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: white;
  border: 1px solid white;
  padding: 0.5rem;
  width: auto;
  pointer-events: all;
  background: #000;
  @media screen and (max-size: 576px) {
    width: 180px;
  }
  .pwa-heading {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 14px;
    h3 {
      font-size: 1.5rem;
    }
  }
  .close-icon {
    background: transparent;
    border: none;
    outline: none;
    margin-bottom: 5px;
    color: #f6a50e;
    width: 100%;
    text-align: right;
  }
  .pwa-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100px;
      background: transparent;
      outline: none;
      display: flex; /* Use flexbox for alignment */
      align-items: center; /* Vertically center-align the icon and text */
      justify-content: center; /* Center-align overall content (optional) */
      width: auto;
      background-color: green;
      border-color: green;
      color: #fff;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      svg {
        margin-right: 8px; /* Adds spacing between icon and text */
        display: inline-block;
      }
    }
  }
  a {
    color: #20a8d8;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    cursor: pointer;
  }
}
.close-button {
  position: absolute;
  top: -14px;
  right: -14px;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: white;
  cursor: pointer;
  color: black;

  img {
    width: 100%;
  }
}
.pwa-prompt::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim effect */
  z-index: -1; /* Ensure it stays behind the popup */
}
body.modal-open {
  overflow: hidden !important; /* Scrolling disable karega */
  pointer-events: none; /* Click disable karega */
}
</style>
