const dur = 1000;

AFRAME.registerSystem("transition", {
  transitionBubble: null,
  transitionVideo: null,
  vuePage: null,

  init: function () {
    this.el.addEventListener("renderstart", () => {
      this.transitionBubble = document.getElementById("transition-bubble");
      this.transitionVideo = document.getElementById("transition-video");
      this.vueElements = document.querySelectorAll("#vue");
    });
  },

  play: function (active) {
    return new Promise((resolve) => {
      if (active) {
        this.el.emit("setTransitioning", true);
      }

      setTimeout(() => {
        if (this.transitionBubble) {
          this.transitionBubble.setAttribute("animation__transition", {
            property: "components.material.material.opacity",
            delay: active ? 0 : 250,
            dur,
            easing: "linear",
            to: active ? 1 : 0,
          });
        }

        if (active) {
          this.vueElements.forEach((el) => el.classList.add("transitioning"));
          document.body.classList.add("transitioning");
        }
      });

      setTimeout(
        () => {
          if (!active) {
            this.el.emit("setTransitioning", false);
            this.vueElements.forEach((el) =>
              el.classList.remove("transitioning")
            );
            document.body.classList.remove("transitioning");
          }

          this.sceneEl.resize();
          resolve();
        },
        active ? dur : 250
      );
    });
  },

  waitForLoaderToHide: function () {
    setTimeout(async () => {
      if (this.el.systems["loader"].isLoading() === false) {
        this.play(false);
        return Promise.resolve();
      }

      await this.el.systems["loader"].waitForLoaderToHide();
      this.play(false);
    });
  },
});
