import { getAsset } from "../utils/assets.utils";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import { Endpoints } from "../../endpoints";
import { waitForLoaderToHide } from "../utils/scene.utils";
import { getState, transitionTo } from "../../vue/utils/aframe.utils";
import ToggleVolume from "../../vue/components/toggle-volume.component.vue";
import { sendOnVideoStart } from "../../vue/utils/dataCollection.utils";
import { resetOrbitalCamera } from "../utils/camera.utils";
import { requestDeviceOrientation } from "../utils/device-orientation.utils";

AFRAME.registerComponent("video-scene-controller", {
  events: {
    sceneEnter: async function (e) {
      const { debug } = getState();
      const { routeParameters } = e.detail;
      const { category, submenu = "", id } = routeParameters;

      if ((e.detail.firstScene || !id) && !debug) {
        setTimeout(() => {
          this.el.sceneEl.systems["router"].navigate([category, submenu, id], {
            animate: false,
          });
        });
        return;
      }

      const { src, srcAndroid, backScene } = await this.el.sceneEl.systems[
        "data"
      ].fetch(Endpoints.models(id, category));

      this.category = category;
      this.backScene = backScene;

      const hasOrientationPermission = await requestDeviceOrientation();
      this.useLookControls =
        hasOrientationPermission ||
        (!getState().isIOS && getState().mouseDetected);
      if (this.useLookControls) {
        this.el.sceneEl.emit("activateLookCamera");
      } else {
        this.el.sceneEl.emit("activateOrbitalCamera", {
          minDistance: 1,
          maxDistance: 1,
          panSpeed: 0.1,
          rotateSpeed: -0.1,

          target: { x: 0, y: 0, z: -5 },
        });
      }
      this.elVideoSphere.setAttribute("visible", false);
      this.el.sceneEl.camera.el.setAttribute("rotation", "0 0 0");

      setTimeout(async () => {
        // const videoSrc = getState().isAndroid ? srcAndroid : src;
        const videoSrc = `https://360fabriek-storage-drdhfqcsgcctcubg.z03.azurefd.net/xr-academy/360videos/${category}/${id}.mp4`;

        await this.loadVideo(videoSrc);
        this.elHeader.setAttribute("generic-menu-header", {
          asset: id,
          category,
          enabled: true,
          submenu,
        });
        waitForLoaderToHide();

        // send video load event
        sendOnVideoStart({ contentName: id, contentCategory: submenu });
      });
    },

    sceneExiting: function () {
      this.elVideo &&
        this.elVideo.pause() &&
        this.elVideo.removeEventListener("ended", this.gotoVideoCategory);

      if (this.vueToggleVolume) {
        removeVueComponent(this.vueToggleVolume);
      }
      this.vueToggleVolume = null;
    },

    sceneExit: function () {
      if (!this.useLookControls && !getState().userIsInVR) {
        resetOrbitalCamera();
      }

      this.el.sceneEl.emit("clearSceneInformation");
      this.elHeader.setAttribute("generic-menu-header", "enabled", false);
      this.elVideoSphere.setAttribute("color", "#000");
    },

    goBack: function () {
      this.gotoVideoCategory();
    },
  },

  category: null,
  elVideoSelector: null,
  elVideoSphere: null,
  elVideo: null,

  init: function () {
    this.gotoVideoCategory = this.gotoVideoCategory.bind(this);
    this.elHeader = document.querySelector(".header");

    // this.elVideoSelector = document.getElementById("video__selector");
    this.elVideoSphere = document.getElementById("video__sphere");
  },

  loadVideo: async function (src) {
    this.elVideoSphere.removeAttribute("animation");
    this.elVideoSphere.setAttribute("material", "color", "#000");
    this.elVideoSphere.setAttribute("visible", false);

    const video = await getAsset(src, { showLoader: true });
    video.currentTime = 0;
    const { browser, isIOS } = getState();
    if (browser.name === "safari" || isIOS) {
      this.vueToggleVolume = addVueComponent(ToggleVolume);
      this.vueToggleVolume.addEventListener("buttonPressed", () => {
        video.muted = false;
        removeVueComponent(this.vueToggleVolume);
        this.vueToggleVolume = null;
      });
    } else {
      video.muted = false;
    }

    video.volume = 1;
    video.play();
    video.addEventListener("ended", this.gotoVideoCategory);

    this.elVideoSphere.setAttribute("animation", {
      easing: "linear",
      property: "components.material.material.color",
      from: "#000",
      to: "#fff",
      type: "color",
    });
    this.elVideoSphere.setAttribute(
      "material",
      "src",
      `#${video.getAttribute("id")}`
    );
    this.elVideoSphere.setAttribute("visible", true);
    this.elVideo = video;

    return true;
  },

  gotoVideoCategory: function (doNotAnimate) {
    transitionTo(this.backScene.split("/"));
  },
});
