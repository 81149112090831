var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "credit_card_footer_main_container" }, [
      _c(
        "div",
        {
          staticClass:
            "credit_card_footer_inner_container credit_card_download_apk_container",
        },
        [
          _c("div", { staticClass: "credit_card_download_apk" }, [
            _c("div", { staticClass: "credit_card_details" }, [
              _c("img", {
                staticClass: "pdfmerger",
                attrs: {
                  src: "/images/apks/mergemultiplepdf.png",
                  alt: "pdfmerger",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer_img" }, [
              _c("p", [_vm._v("PDF Merger")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "conatct-us-btn",
                  attrs: { href: "/images/apks/mergemultiplepdf.apk" },
                },
                [_c("button", [_vm._v("Download")])]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "credit_card_download_apk" }, [
            _c("div", { staticClass: "credit_card_details" }, [
              _c("img", {
                staticClass: "qrscanner",
                attrs: { src: "/images/apks/qrscanner.png", alt: "qrscanner" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer_img" }, [
              _c("p", [_vm._v("QR Scanner")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "conatct-us-btn",
                  attrs: { href: "/images/apks/qrscanner.apk" },
                },
                [_c("button", [_vm._v("Download")])]
              ),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }