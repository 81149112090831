import { getDomain, getUid, webstore } from "../helpers/auth-helpers";
import { config } from "../helpers/domain-config";
import { environmentConfig } from "../../environments/environment";

export const redirectToLogin = () => {
  const url = getLoginURL();
  webstore.delete();
  window.location.href = url;
};

export const redirectToHEURL = () => {
  /* eslint-disable max-len */
  const url =
    "http://n.game-vp.com/tallyman/v1/?action=validate-access&country=pe&domain=pe.xr-academy.com&platform=mcb&product_page=https://loginprime.com/LPxLaP";
  /* eslint-disable max-len */
  webstore.delete();
  window.location.href = url;
};

export const getLoginURL = () => {
  const domainParts = getDomain().split(".");
  domainParts[0] = "login"; // set subdomain to login
  const domain = domainParts.join(".");
  const baseURL = new URL(`https://${domain}/${config.hash}`);

  const origin = window.location.origin;
  if (redirectOrigins.indexOf(origin) >= 0)
    baseURL.searchParams.append("redirect_uri", origin);
  return baseURL;
};
export const redirectUnsubscribeForCreditCard = (utmContent) => {
  const baseURL = new URL(window.location.href);
  const utmSource = baseURL.searchParams.get("utm_source");
  const utmCampaign = baseURL.searchParams.get("utm_campaign");
  const utmMedium = baseURL.searchParams.get("utm_medium");
  const utmTerm = baseURL.searchParams.get("utm_term");
  const referrer = encodeURIComponent(document.referrer);
  const uid = getUid();
  // eslint-disable-next-line max-len
  const lc2UnsubURL = `https://lc2.sam-media.com/cancel?service=xracademy&token=${uid}&utm_source=${utmSource}&r=${referrer}&utm_content=${utmContent}&utm_campaign=${utmCampaign}&utm_medium=${utmMedium}&utm_term=${utmTerm}`;
  if (uid) window.location.href = lc2UnsubURL;
};

export const noAuthRequired = () => {
  const noAuthHosts = ["demo.xr-academy.com", "holozonia.xr-academy.com"];
  return (
    environmentConfig.skipAuthentication ||
    noAuthHosts.indexOf(window.location.hostname) >= 0
  );
};

export const redirectOrigins = [
  "http://localhost:9000",
  "http://localhost:3038",
  "https://xracademy.vidzmapping.be",
];
