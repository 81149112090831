import { render, staticRenderFns } from "./toggle-volume.component.vue?vue&type=template&id=510e4292"
import script from "./toggle-volume.component.vue?vue&type=script&lang=js"
export * from "./toggle-volume.component.vue?vue&type=script&lang=js"
import style0 from "./toggle-volume.component.vue?vue&type=style&index=0&id=510e4292&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports