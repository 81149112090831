//
const domainConfig = {
  demo: {
    country: "xx",
    lang: "en",
  },
  demo2: {
    country: "xx",
    lang: "en",
  },
  om: {
    country: "om",
    lang: "en",
  },
  ca: {
    country: "ca",
    lang: "en",
  },
  de: {
    country: "de",
    lang: "de",
  },
  za: {
    country: "za",
    lang: "en",
  },
  ps: {
    country: "ps",
    lang: "ar",
  },
  sa: {
    country: "sa",
    lang: "ar",
  },
  cz: {
    country: "cz",
    lang: "cz",
  },
  qa: {
    country: "qa",
    lang: "en",
  },
  nl: {
    country: "nl",
    lang: "nl",
  },
  co: {
    country: "co",
    lang: "es",
  },
  my: {
    country: "my",
    lang: "en",
  },
  rs: {
    country: "rs",
    lang: "rs",
  },
  th: {
    country: "th",
    lang: "th",
  },
  thtd: {
    country: "th",
    lang: "th",
  },
  es: {
    country: "es",
    lang: "es",
  },
  ee: {
    country: "ee",
    lang: "en",
  },
  id: {
    country: "id",
    lang: "in",
  },
  ae: {
    country: "ae",
    lang: "ar",
  },
  iq: {
    country: "iq",
    lang: "ar",
  },
  holozonia: {
    country: "xx",
    lang: "en",
  },
  kw: {
    country: "kw",
    lang: "ar",
  },
  pl: {
    country: "pl",
    lang: "pl",
  },
  se: {
    country: "se",
    lang: "se",
  },
  bh: {
    country: "bh",
    lang: "ar",
  },
  pk: {
    country: "pk",
    lang: "en",
  },
  portal: {
    country: "XX",
    lang: "en",
  },
  "id-tel": {
    country: "id",
    lang: "in",
  },
  fr: {
    country: "fr",
    lang: "fr",
  },
  ch: {
    country: "ch",
    lang: "de",
  },
  pe: {
    country: "pe",
    lang: "es",
  },
  umobile: {
    country: "my",
    lang: "en",
  },
};

const loginPrimeHash = [
  {
    domain: "om.xr-academy.com",
    hash: "GOaZPw",
  },
  {
    domain: "demo.xr-academy.com",
    hash: "joBeP1",
  },
  {
    domain: "holozonia.xr-academy.com",
    hash: "joBeP1",
  },
  {
    domain: "ca.mobiovr.com",
    hash: "bOWVyG",
  },
  {
    domain: "de.xr-academy.com",
    hash: "vOKby0",
  },
  {
    domain: "za.xr-academy.com",
    hash: "0PjKo7",
  },
  {
    domain: "ps.xr-academy.com",
    hash: "VPY0O7",
  },
  {
    domain: "sa.xr-academy.com",
    hash: "WolWP1",
  },
  {
    domain: "cz.xr-academy.com",
    hash: "LPxaoJ",
  },
  {
    domain: "qa.xr-academy.com",
    hash: "KOq6PQ",
  },
  {
    domain: "nl.xr-academy.com",
    hash: "dOp3y1",
  },
  {
    domain: "co.mobiovr.com",
    hash: "2oQnyp",
  },
  {
    domain: "my.xr-academy.com",
    hash: "GPkZOg",
  },
  {
    domain: "rs.xr-academy.com",
    hash: "GPevox",
  },
  {
    domain: "th.xr-academy.com",
    hash: "4P7JOE",
  },
  {
    domain: "es.xr-academy.com",
    hash: "BOmbPN",
  },
  {
    domain: "thtd.xr-academy.com",
    hash: "pPdNy5",
  },
  {
    domain: "ee.xr-academy.com",
    hash: "VyXBPW",
  },
  {
    domain: "id.xr-academy.com",
    hash: "jO8YP2",
  },
  {
    domain: "sa.mobiovr.com",
    hash: "5y4woq",
  },
  {
    domain: "ae.mobiovr.com",
    hash: "bO6bOM",
  },
  {
    domain: "iq.xr-academy.com",
    hash: "qy3xO1",
  },
  {
    domain: "kw.mobiovr.com",
    hash: "6OGYo9",
  },
  {
    domain: "pl.xr-academy.com",
    hash: "rOVWPg",
  },
  {
    domain: "se.xr-academy.com",
    hash: "eOARPb",
  },
  {
    domain: "bh.xr-academy.com",
    hash: "wywBor",
  },
  {
    domain: "ae.xr-academy.com",
    hash: "8P1qvP",
  },
  {
    domain: "kw.xr-academy.com",
    hash: "nyb77O",
  },
  {
    domain: "pk.xr-academy.com",
    hash: "woR1EP",
  },
  {
    domain: "id-tel.xr-academy.com",
    hash: "vOK2by",
  },
  {
    domain: "portal.xracademy.online",
    hash: "7OJpLo",
  },
  {
    domain: "portal.xr-academy.com",
    hash: "joB1eP",
  },
  {
    domain: "portal.xr-academy.online",
    hash: "EPrxy6",
  },
  {
    domain: "fr.xr-academy.com",
    hash: "bOWxVP",
  },
  {
    domain: "ch.xr-academy.com",
    hash: "VPYz0o",
  },
  {
    domain: "pe.xr-academy.com",
    hash: "LPxLaP",
  },
  {
    domain: "umobile.xr-academy.com",
    hash: "WolYWy",
  },
  {
    domain: "portal.xracademy.info",
    hash: "jyD7Qo",
  },
];

// STRINGS USED HERE HAS TO BE IN LINE WITH THE DOMAIN OF WEBAPP(eg: www.mobiovr.com)
export const PRODUCT_XR_ACADEMY = "xr-academy";
export const PRODUCT_XRACADEMY = "xracademy";
export const PRODUCT_MOBIO_VR = "mobiovr";
export const COMPANY_SAM_MEDIA = "SAM_MEDIA";
export const COMPANY_TREND_TECH = "TREND_TECH";
export const STATC_PAGES = [
  "/terms-and-conditions",
  "/help-center",
  "/faq",
  "/refund-policy",
  "/privacy-policy",
];
export const restrictedSubDomains = ["fr", "cz", "se", "pl", "ch"];

export const HELP_CENTER_UTM_CAMPAIGN = "unsubscription-helpCenter";
export const MODAL_UTM_CAMPAIGN = "unsubscription-modal";

/* eslint-disable max-len */
export const modalStatusMsg = {
  success: `<strong>Congratulations!</strong> Your number has won a prize! Your Voucher Number. <strong>01234567689</strong> Your XR Academy OTT voucher can be redeemed online, for partner go to <a href="https://ottvoucher.com/frequently-asked-questions/" style="color:white;"> https://ottvoucher.com/frequently-asked-questions/</a>`,
  fail: "Sorry, your number did not win but not worry, next month we will give you a chance again.",
  error: "Something went wrong!",
};

export { domainConfig, loginPrimeHash };
