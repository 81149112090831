<template>
  <div class="main-menu">
    <Background
      src="https://360fabriek-storage-drdhfqcsgcctcubg.z03.azurefd.net/xr-academy/backgrounds/main-menu.jpg"
    ></Background>
    <div class="content">
      <div
        v-if="isDisableQuizz"
        class="center-image disable-quizz-center-image-bg"
      >
        <div class="text" :class="{ 'right-align': selectedLanguage == 'ar' }">
          <h1>
            {{ centerImage.title }}
          </h1>
        </div>
      </div>
      <div v-if="!isDisableQuizz" class="center-image">
        <div class="text" :class="{ 'right-align': selectedLanguage == 'ar' }">
          <div class="small animated fadeInUp delay7s">
            {{ centerImage.title }}
          </div>
          <div class="large animated fadeInUp delay7_25s">
            {{ centerImage.content }}
          </div>
          <div class="medium animated fadeInUp delay7_25s">
            {{ centerImage.content2 }}
          </div>
        </div>
        <div class="center-image-middle">
          <div class="animation-group">
            <img
              src="/images/main-menu/quiz_poster_before.png"
              class="animated zoomIn delay7_25s"
              alt=""
            />
            <img
              src="/images/main-menu/quiz_poster_after.png"
              class="animated zoomIn delay7s"
              alt=""
            />
          </div>
        </div>
        <div class="start-btn-container" v-if="isDisableQuizz === false">
          <a
            :href="quizLink"
            id="start-btn"
            :class="{ disabled: isDisableQuizz === true }"
          >
            <span class="btn-elements">
              <span class="btn-text">{{ centerImage.startquiz }}</span>
              <span class="animation-group">
                <img
                  src="/images/main-menu/start_arrow.png"
                  alt="start"
                  class="arrow-mover"
                />
                <img
                  src="/images/main-menu/start_arrow_bg.png"
                  alt="start-mover"
                />
              </span>
            </span>
          </a>
        </div>
      </div>
      <div
        v-if="Object.keys(buttons).length !== 0"
        class="menuButton_conatiner1"
        :class="{ 'menu-button-right': selectedLanguage == 'ar' }"
      >
        <MainMenuButton
          id="kingdom-of-living-things"
          :title="buttons.kingdomOfLivingThings"
          icon-height=""
          icon-width="70px"
          icon-left-position=""
          @click="navigateTo('kingdom-of-living-things')"
        ></MainMenuButton>
        <MainMenuButton
          id="human-body"
          :title="buttons.humanBody"
          icon-height=""
          icon-width="120px"
          icon-position="25px"
          @click="navigateTo('human-body')"
        ></MainMenuButton>
        <MainMenuButton
          id="space-science"
          :title="buttons.spaceScience"
          icon-height=""
          icon-width="120px"
          icon-position="10px"
          @click="navigateTo('space-science')"
        ></MainMenuButton>
      </div>
      <div
        class="menuButton_container2"
        :class="{ 'menu-button-left': selectedLanguage == 'ar' }"
      >
        <MainMenuButton
          id="art"
          :title="buttons.art"
          icon-height=""
          icon-width="70px"
          icon-position="-5px"
          @click="navigateTo('art')"
        ></MainMenuButton>
        <MainMenuButton
          icon-height=""
          icon-width="120px"
          icon-left-position=""
          id="cultural-heritage-history"
          :title="buttons.culturalHeritageHistory"
          @click="navigateTo('cultural-heritage-history')"
        ></MainMenuButton>
        <MainMenuButton
          id="archaeology"
          :title="buttons.archaeology"
          icon-height=""
          icon-width="90px"
          icon-left-position=""
          @click="navigateTo('archaeology')"
        ></MainMenuButton>
      </div>
    </div>
    <ChatGpt ref="chatgptComponentChild"></ChatGpt>
  </div>
</template>

<script>
import Background from "../components/background.component.vue";
import MainMenuButton from "../components/main-menu-button.component.vue";
import { transitionTo, getGenericData } from "../utils/aframe.utils";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
import { sendPageView } from "../utils/dataCollection.utils";
import { webstore } from "../helpers/auth-helpers.js";
import {
  checkIsDisableQuizzSubDomains,
  getSubdomain,
} from "../helpers/domain-config.js";
import ChatGpt from "../components/chatgpt.component.vue";

export default {
  components: {
    Background,
    MainMenuButton,
    ChatGpt,
  },
  data: () => ({
    buttons: {
      type: Object,
      default: {},
    },
    centerImage: {
      type: Object,
      default: {
        title: "",
        content: "",
      },
    },
    selectedLanguage: getSelectedLanguage(),
    isDisableQuizz: checkIsDisableQuizzSubDomains(),
  }),
  methods: {
    navigateTo360Category(category) {
      this.navigateTo(`videos/${category}`);
    },
    navigateTo(commands) {
      transitionTo(commands.split("/"));
    },
  },
  computed: {
    quizLink() {
      if (this.isDisableQuizz) {
        return "";
      } else {
        const token = webstore.getProperty("token") || "fdf098fcc6";
        const subdomain = getSubdomain();

        // window.location.href = `http://localhost:5173/?uid=${userToken}`;
        return `https://quiz.xr-academy.com?uid=${token}&subdomain=${subdomain}`;
      }
    },
  },
  async created() {
    const { homeCenterImage, homeCategories } = await getGenericData();
    this.buttons = homeCategories;
    this.centerImage = homeCenterImage;
  },
  mounted() {
    sendPageView();
    const elChatGpt = this.$refs.chatgptComponentChild.$el;
    if (elChatGpt) {
      elChatGpt.classList.add("animated");
      elChatGpt.classList.add("visible");
    }
  },
};
</script>

<style scoped lang="scss">
.main-menu {
  .credit-card-content {
    margin-bottom: 14% !important;
  }
  .content {
    margin: 0;
    padding: 20vw 20px;
    font-size: 12px;
    .disable-quizz-center-image-bg {
      background-image: url("/images/main-menu/center.png") !important;
    }

    .center-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("/images/main-menu/center2.png");
      background-position: center;
      color: white;
      display: flex;
      flex-direction: column;
      min-height: 500;
      .text {
        flex-grow: 0;
        padding: 20px 30px 10px 30px;
        color: #fff;

        .small {
          font-size: 12px;
        }

        .medium {
          font-size: 14px;
          font-weight: bold;
        }

        .large {
          font-size: 24px;
          font-weight: bold;
        }
      }
      .center-image-middle {
        position: relative;
        height: 300px;
        .animation-group {
          left: 20;
          right: 0;
          display: block;
          position: absolute;
          img {
            position: absolute;
            height: 300;
          }
        }
        .animated {
          animation-duration: 1s;
          animation-fill-mode: both;

          &.infinite {
            animation-iteration-count: infinite;
          }
        }
        .zoomIn {
          animation-name: zoomIn;
        }
      }
      .right-align {
        text-align: right;
      }
      .start-btn-container {
        display: block;
        #start-btn {
          flex-grow: 0;
          color: #fff;
          width: 287px;
          height: 46px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 10px;
          display: flex;
          cursor: pointer;
          position: relative;
          align-items: center;
          text-decoration: none;
          justify-content: space-between;
          background-image: url(/images/main-menu/start_btn_bg.png);
          .btn-text {
            font-size: 20px;
            padding-left: 20px;
          }
          .animation-group {
            top: 8px;
            left: 205px;
            position: absolute;
            right: 0;
            margin: auto;
            display: block;
            img {
              position: absolute;
            }
            .arrow-mover {
              animation: 1s arrow_mover steps(2) infinite;
            }
          }
        }
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .fadeInUp {
        animation-name: fadeInUp;
      }
    }
    .header-component {
      width: 100%;
      padding-bottom: 10px;
    }
    .hide-for-fr {
      display: none;
    }
    .chatbot-container {
      margin-left: 100vw;
      z-index: 25;
      &.visible {
        margin-left: 0;
      }
    }
  }

  .delay-0 {
    animation-delay: 0s;
  }
  .delay-1 {
    animation-delay: 0.25s;
  }
  .delay-2 {
    animation-delay: 0.5s;
  }
  .delay-3 {
    animation-delay: 0.75s;
  }
  .delay-4 {
    animation-delay: 1s;
  }
  .delay-5 {
    animation-delay: 1.25s;
  }
  .delay7s {
    animation-delay: 7s;
  }
  .delay7_25s {
    animation-delay: 7.25s;
  }
  .delay8s {
    animation-delay: 8s;
  }
  .header {
    width: 100%;
  }
  @media screen and (min-width: 500px) {
    .content {
      .center-image {
        height: 85%;
      }
    }
  }
  @media screen and (max-width: 385px) {
    .content {
      .center-image {
        height: 480px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .content {
      .center-image {
        height: 400px;
        .text {
          h1 {
            &:nth-child(2) {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 420px) and (orientation: landscape) {
    .content {
      display: flex;
      padding: 9vw 12px;
      .disable-quizz-center-image-bg {
        min-height: unset !important;
      }

      .header {
        width: 30%;
        order: 2;
        margin: 1%;
      }
      .center-image {
        order: 2;
        margin: 6px 9px;
        height: unset;
        width: 46%;
        .text {
          h1 {
            &:nth-child(1) {
              font-size: 15px;
            }
            &:nth-child(2) {
              font-size: 26px;
            }
          }
        }
      }
      .menuButton_conatiner1 {
        display: flex;
        flex-direction: column;
        width: 56%;
        order: 3;
      }
      .menuButton_container2 {
        display: flex;
        flex-direction: column;
        width: 56%;
      }
      .menu-button-left {
        order: 3 !important;
      }
      .menu-button-right {
        order: 1 !important;
      }
    }
  }
  @media screen and (max-width: 668px) and (orientation: landscape) {
    .content {
      .center-image {
        //height: 261px;
        //width: 48%;
        .text {
          h1 {
            &:nth-child(1) {
              font-size: 13px;
            }
            &:nth-child(2) {
              font-size: 21px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) and (orientation: landscape) {
    .content {
      .center-image {
        height: 216px;
        .text {
          h1 {
            &:nth-child(2) {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
  @keyframes arrow_mover {
    0% {
      opacity: 0;
      transform: translateX(-30px);
    }

    50% {
      opacity: 1;
      transform: translateX(30px);
    }

    100% {
      opacity: 1;
      transform: translateX(30px);
    }
  }
  @keyframes zoomIn {
    from {
      opacity: 0;

      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;

      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;

      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
